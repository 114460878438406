import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../utils/common";
import { Auth } from "aws-amplify";
import { CiWarning } from "react-icons/ci";
import HeaderSubPart from "./HeaderSubPart";

let libertatelogo = require("../../images/libertate.png");
let insurecomplogo = require("../../images/new-logo.png");
let altMarketLogo = require("../../images/alt_market_logo.png");

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoSize: "large",
      loggedInPeo: undefined,
    };
  }

  componentDidMount = async () => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      if (sessionData) {
        let userType = sessionData.attributes["custom:user_type"];
        if (userType === "peo") {
          let email = sessionData.attributes.email;
          let user_peo = email.split("@")[0];
          this.setState({ loggedInPeo: user_peo });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    let isMobile = window.innerWidth < 900;
    let { logoSize, loggedInPeo } = this.state;
    let isLoggedIn = sessionStorage?.getItem('isLoggedIn')
    //Sharina asked to comment the libertate logo in dev libertate

    return (
      <>
        <nav
          id="navbarMain"
          className="new-navbar container-fluid  nav_fontsize "
          style={{ backgroundColor: "white", height: "auto", fontSize: "16px", padding: "0px" }}
        >
          <div className="row justify-content-between align-items-center w-100 no-gutters">
            {process.env.REACT_APP_MODE === "prod" ? (
              <Link
                to={"/"}
                className="navbar-brand mx-0 d-flex align-items-center navlisting"
              >
                <img
                  className="imagesizelogo"
                  id="imagesizelogo"
                  style={
                    logoSize === "large"
                      ? { height: "100px", marginLeft: "2rem" }
                      : { height: "100px", marginLeft: "1.6rem" }
                  }
                  src={loggedInPeo === "ies" ? altMarketLogo : libertatelogo}
                  alt="libertate logo"
                />
              </Link>
            ) : (
              <div></div>
            )}

            {isMobile ? " " : <HeaderSubPart />}

            <Link
              to={"/"}
              className="navbar-brand mx-0 d-flex align-items-center"
              style={{ flexDirection: "column" }}
            >
              <img
                className="imagesizelogoicomp"
                id="imagesizelogoicomp"
                style={
                  logoSize === "large"
                    ? {
                      height: "70px",
                      marginRight: "2rem",
                    }
                    : {
                      height: "71px",
                      marginRight: "1rem",
                    }
                }
                src={insurecomplogo}
                alt="insurecomp logo"
              />
            </Link>
            {isLoggedIn && <div id='temperory' style={{ width: "100%", border: "1px solid #F4B0A1", padding: "10px", boxSizing: "border-box", display: "flex", backgroundColor: "#FFF3F0" }}>
              <div style={{
                color: "#D92D20", margin: "auto", fontSize: "0.65rem",
                fontstyle: "normal",
                fontWeight: "800",
                lineHeight: "1.75rem",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                flexDirection: "row",
              }}>
                <div><CiWarning style={{ width: "20px", height: "20px", marginRight: "5px", marginBottom: "7px" }} /></div>
                <div> Please Note: "Our portal is undergoing enhancements related to the recent premium algorithm changes for California. We request you to avoid using the portal for quoting CA exposures until the updates are complete. We appreciate your understanding!"</div>
              </div>
            </div>}
          </div>
        </nav >
      </>
    );
  }
}

export default Header;
